/* eslint-disable react/prop-types */
import React from 'react';
import Helmet from 'react-helmet';

function SEO({ title, ogUrl, ogImg }) {
  return (
    <Helmet>
      <title>Emvelia Technologies | {title}</title>
      <meta name="description" content="An Innovation Technology company building state-of-the-art solutions for Healthcare, Manufacturing, Shipping, Finance, Agriculture, Sound & Acoustics, Online Retail and other eco-friendly sectors." />
      <meta name="copyright" content="Emvelia Technologies ©" />
      <meta name="author" content="Emvelia Technologies" />
      <meta property="og:type" content="Article" />
      <meta property="og:description" content="An Innovation Technology company building state-of-the-art solutions for Healthcare, Manufacturing, Shipping, Finance, Agriculture, Sound & Acoustics, Online Retail and other eco-friendly sectors." />
      <meta property="og:site_name" content="Emvelia Technologies" />
      <meta property="og:url" content={ogUrl || 'https://emvelia.com'} />
      <meta property="og:image" content={ogImg || '/assets/header.jpg'} />
      <meta property="twitter:title" content="Emvelia Technologies" />
      <meta property="twitter:description" content="An Innovation Technology company building state-of-the-art solutions for Healthcare, Manufacturing, Shipping, Finance, Agriculture, Sound & Acoustics, Online Retail and other eco-friendly sectors." />
      <meta property="twitter:image" content="/assets/header.jpg" />
      <meta property="twitter:site" content="Emvelia Technologies" />
      <meta property="twitter:creator" content="Emvelia Technologies" />

      <script async src="https://www.googletagmanager.com/gtag/js?id=G-MJ7VGG8FKW" />
      <script>{`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-MJ7VGG8FKW');
    `}
      </script>

    </Helmet>
  );
}

export default SEO;
